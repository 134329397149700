import React, { useState } from "react";
import { Link } from "react-router-dom";
import NavbarOffic from "../components/componetsNavbar/NavbarOffic";
import ProductForm from "../components/componets-Produtor/ProdutoFormCria";

const CreateProdutos = () => {
  // Estado para armazenar os dados do produto
  const [produtoData, setProdutoData] = useState({
    nome_produto: "",
    imagem_url: "",
    preco: "",
    tamanho: "",
    estoque: "",
    descricao: "",
    usuario_id: "",
  });

  // Função para lidar com a alteração nos campos de entrada
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProdutoData((prevData) => ({
      ...prevData,
      [name]: name === "nome_produto" ? value.toUpperCase() : value,
    }));
  };

  const handleImageUrlChange = (url) => {
    setProdutoData((prevData) => ({ ...prevData, imagem_url: url }));
  };

  // Função para lidar com o envio do formulário
  const handleSubmit = async (e) => {
    e.preventDefault(); // Para evitar a atualização da página
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST_API}/produtos`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(produtoData),
        }
      );

      if (response.status === 201) {
        alert("Produto cadastrado com sucesso!");
        // Limpar os campos do formulário após o sucesso
        setProdutoData({
          nome_produto: "",
          imagem_url: "",
          preco: "",
          tamanho: "",
          estoque: "",
          descricao: "",
          usuario_id: "",
        });
      } else {
        alert("Erro ao cadastrar o produto.");
      }
    } catch (error) {
      console.error("Erro ao cadastrar o produto:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <NavbarOffic />
      <div className="py-4 w-full flex justify-center bg-white shadow">
        <ol className="list-reset flex text-gray-600">
          <li>
            <Link
              to="/"
              className="transition duration-150 ease-in-out hover:text-blue-500 focus:text-blue-500"
            >
              Home
            </Link>
          </li>
          <li>
            <span className="mx-2">/</span>
          </li>
          <li>
            <Link
              to="/Produtos"
              className="transition duration-150 ease-in-out hover:text-blue-500 focus:text-blue-500"
            >
              Produtos
            </Link>
          </li>
          <li>
            <span className="mx-2">/</span>
          </li>
          <li className="text-gray-500">Criar Produto</li>
        </ol>
      </div>
      <div className="mt-8 flex justify-center">
        <div className="w-full max-w-lg CorAZulFundo border border-gray-200 rounded-lg shadow-md">
          <ProductForm
            produtoData={produtoData}
            handleInputChange={handleInputChange}
            handleImageUrlChange={handleImageUrlChange}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateProdutos;
