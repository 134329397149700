import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Rotas from "./routes/Routes";
import "./global.css";
import { createRoot } from 'react-dom/client';
import { AuthProvider } from "../src/components/componetsLogin/AuthContext";

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <AuthProvider>
      <Router>
        <Rotas />
      </Router>
    </AuthProvider>
  </React.StrictMode>
);
