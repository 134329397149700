// LogoComponent.js
import React from "react";
// import logo from '../../assets/Logo_TechnoTrends.png';
import logo from "../../assets/icons/LogoYobom.png";

const LogoComponent = () => {
  return (
    <div className=" flex h-10 items-center justify-center">
      {/* <img src={logo} className="h-full" alt="Flowbite Logo" /> */}
      {/* <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">  
        TechnoTrends
      </span> */}
      <span className="flex h-12 items-center">
        <img src={logo} className="h-full" alt="Flowbite Logo" />
        {/* TechnoTrends */}
      </span>
    </div>
  );
};

export default LogoComponent;
