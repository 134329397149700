import React, { useState, useEffect, useCallback } from "react";

const CaixaTab = ({ onTotalChange }) => {
  const [depositoList, setDepositoList] = useState([]);

  const fetchDepositoData = async () => {
    try {
      const depositoResponse = await fetch(
        `${process.env.REACT_APP_HOST_API}/caixa`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!depositoResponse.ok) {
        throw new Error("Failed to fetch items");
      }

      const depositoData = await depositoResponse.json();
      setDepositoList(depositoData);
    } catch (error) {
      console.error("Error fetching deposito data:", error);
    }
  };

  useEffect(() => {
    fetchDepositoData();
  }, []);

  const formatMoney = (value) => {
    return `R$${value}`;
  };

  const calculateTotalPreco = useCallback(() => {
    return depositoList.reduce((total, deposito) => {
      const precoNumero = parseFloat(deposito.total_pago);
      return !isNaN(precoNumero) ? total + precoNumero : total;
    }, 0);
  }, [depositoList]);

  useEffect(() => {
    const totalPreco = calculateTotalPreco();
    onTotalChange(totalPreco);
  }, [onTotalChange, calculateTotalPreco]);

  return (
    <div className="shadow-xl CorAZulFundo lg:w-1/4 w-full h-72 p-3.5 lg:rounded-2xl border-solid border-2 border-black ...">
      <div className="w-full h-full overflow-auto ...">
        <table className="w-full border-collapse border border-slate-500 ...">
          <thead>
            <tr>
              <th className="cortes border border-slate-600 ...">Cliente</th>
              <th className="cortes border border-slate-600 ...">Total valor</th>
              <th className="cortes border border-slate-600 ...">Total Pago</th>
              <th className="cortes border border-slate-600 ...">Divida</th>
            </tr>
          </thead>
          <tbody>
            {depositoList.map((deposito, index) => (
              <tr key={index}>
                <td className="border border-slate-700">
                  {deposito.nome_cliente}
                </td>
                <td className="border border-slate-700">
                  {formatMoney(deposito.total_valor)}
                </td>
                <td className="border border-slate-700">
                  {formatMoney(deposito.total_pago)}
                </td>
                <td className="border border-slate-700">
                  {formatMoney(deposito.total_faltante)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CaixaTab;
