import React, { useState, useEffect, useRef } from "react";
import { format } from "date-fns";
import IconUser from "../componetsIcons/IconUser";
import PrintButtonA4 from "../componetsPedidos/PrintButtonA4";
import ProductTable from "./ProductTable";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const ComponetsFinanceiro = () => {
  const [isLoading, setIsLoading] = useState(true); // Inicia o estado como true
  const [caixaData, setCaixaData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const valorRefs = useRef([]);
  const [currentGroup, setCurrentGroup] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_HOST_API}/getCard`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Falha ao obter os dados: ${response.statusText}`);
        }

        const vendasList = await response.json();

        const groupedData = vendasList.reduce((acc, item) => {
          const key = `${item.usuarios_id}-${format(
            new Date(item.data),
            "yyyy-MM-dd"
          )}`;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(item);
          return acc;
        }, {});

        const organizedData = Object.values(groupedData);

        setCaixaData(organizedData);
      } catch (error) {
        console.error("Erro ao obter os dados:", error.message);
      } finally {
        setIsLoading(false); // Desativa o estado de carregamento após os dados serem carregados
      }
    };

    fetchData();
  }, []);

  const finalizarVenda = async (index) => {
    setIsLoading(true);
    try {
      const vendasGrupo = caixaData[index];

      if (!vendasGrupo || vendasGrupo.length === 0) {
        console.error("Nenhum item encontrado para o índice:", index);
        return;
      }

      for (const venda of vendasGrupo) {
        let vendaAtualizada = {
          ...venda,
          dinheiro: parseFloat(venda.dinheiro || 0),
          pix: parseFloat(venda.pix || 0),
          cartao: parseFloat(venda.cartao || 0),
          status: "Pago",
        };

        const finalizarResponse = await fetch(
          `${process.env.REACT_APP_HOST_API}/vendas/${venda.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(vendaAtualizada),
          }
        );

        if (!finalizarResponse.ok) {
          throw new Error(
            `Falha ao finalizar venda. Status: ${finalizarResponse.status}`
          );
        }
      }

      const updatedCaixaData = caixaData.map((group, i) =>
        i === index
          ? group.map((venda) => ({
              ...venda,
              status: "Pago",
            }))
          : group
      );

      if (valorRefs.current[index]) {
        valorRefs.current[index].value = "";
      }

      setCaixaData(updatedCaixaData);

      window.location.reload();
    } catch (error) {
      console.error("Erro ao finalizar venda:", error.message);
    } finally {
      setIsLoading(false); 
    }
  };

  const handleViewProducts = (group) => {
    setSelectedProducts(group);
    setCurrentGroup(group);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProducts([]);
    setCurrentGroup(null);
  };

  const filteredData = caixaData?.filter((group) =>
    group.some(
      (item) =>
        item.nome_cliente.toLowerCase().includes(searchTerm.toLowerCase()) ||
        format(new Date(item.data), "dd/MM/yyyy").includes(searchTerm)
    )
  );

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <AiOutlineLoading3Quarters className="animate-spin text-4xl" />
        </div>
      ) : (
        caixaData && (
          <div>
            <div className="p-5">
              <input
                type="search"
                id="default-search"
                className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Buscar produtos..."
                required
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="flex flex-wrap flex-row items-start w-full">
              {filteredData.map((group, index) => (
                <div
                  key={index}
                  className="flex flex-wrap flex-row items-start w-full md:w-1/3 text-xs text-white p-2"
                >
                  <div className="CorAZulFundo rounded-lg text-white w-full">
                    <div className="p-2 flex border-b justify-around items-center">
                      <div>
                        <span className="flex">
                          <IconUser /> {group[0].nome_cliente}
                        </span>
                        <p>
                          Divida do cliente: R$
                          {parseFloat(group[0].diferenca).toFixed(2)}
                        </p>
                      </div>
                      <div>
                        <p>{group[0].status}</p>
                        <p>{format(new Date(group[0].data), "dd/MM/yyyy")}</p>
                      </div>
                    </div>

                    <div className="p-3 flex w-full justify-around items-center">
                      <button
                        onClick={() => handleViewProducts(group)}
                        className="inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                      >
                        Ver Pedidos
                      </button>
                      <button
                        onClick={() => finalizarVenda(index)}
                        className="inline-flex items-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        disabled={isLoading} 
                      >
                        {isLoading ? (
                          <AiOutlineLoading3Quarters className="animate-spin mr-2" />
                        ) : null}
                        Pedido Pago
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      )}

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-gray-800 opacity-75"></div>
          <div className="bg-white p-5 rounded-lg z-10 lg:w-1/2">
            <div className="lg:h-96 overflow-auto">
              <ProductTable items={selectedProducts} />
            </div>
            <div className="flex justify-between mt-4">
              <div className="inline-flex items-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <PrintButtonA4
                  orders={selectedProducts}
                  caixaData={caixaData}
                  item={selectedProducts[0]}
                />
              </div>
              <button
                onClick={handleCloseModal}
                className="inline-flex items-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComponetsFinanceiro;
