import React, { useState, useEffect } from "react";
import IconUser from "../componetsIcons/IconUser";
import IconCall from "../componetsIcons/IconCall";
import IconHome from "../componetsIcons/IconHome";

const UsuariosTab = () => {
  const [usuariosList, setUsuariosList] = useState([]);
  const fetchUserData = async () => {
    try {
      const usuariosResponse = await fetch(
        `${process.env.REACT_APP_HOST_API}/usuarios`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!usuariosResponse.ok) {
        throw new Error("Failed to fetch users");
      }

      const usuariosData = await usuariosResponse.json();
      setUsuariosList(usuariosData);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  // Chama as funções de busca ao montar o componente
  useEffect(() => {
    fetchUserData();
  }, []);
  return (
    <div className="shadow-xl CorAZulFundo mt-1  lg:w-1/4 w-full h-72 p-3.5 lg:rounded-2xl border-solid border-2 border-black ... ">
      <div className="w-full h-full overflow-auto ...">
        <p>Quantidade de Usuários: {usuariosList.length}</p>
        <table className="w-full  border-collapse   border border-slate-500 ... ">
          <thead>
            <tr>
              <th className="cortes border border-slate-600 ...">CLiente</th>
              <th className="cortes border border-slate-600 ...">Telefone</th>
              <th className="cortes border border-slate-600 ...">Endereço</th>
            </tr>
          </thead>
          <tbody className="testtexto">
            {usuariosList.map((usuario) => (
              <tr key={usuario.id}>
                <td className="border  border-slate-700 ...">
                  <span className="flex m-1">
                    <IconUser /> {usuario.nome}
                  </span>
                </td>
                <td className="border  border-slate-700 ...">
                  <span className="flex  m-1">
                    <IconCall />
                    {usuario.telefone}
                  </span>
                </td><td className="border  border-slate-700 ...">
                  <span className="flex  m-1">
                    <IconHome />
                    {usuario.endereco}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UsuariosTab;
