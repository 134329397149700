import React from "react";
import { format } from "date-fns";

const ProductTable = ({ items }) => {
  // Calcular o Valor Total
  const totalValue = items.reduce(
    (total, item) => total + parseFloat(item.ValorTotal),
    0
  );
  const totalPago = items.reduce(
    (total, item) => total + parseFloat(item.total_pago),
    0
  );

  // Calcular o total de produtos
  const totalProdutos = items.reduce(
    (total, item) => total + parseInt(item.quantidadeSelecionada, 10),
    0
  );

  return (
    <div>
      <table className="w-full lg:text-base text-xs text-center border-collapse border border-slate-500 ...">
        <thead>
          <tr>
            <th className="cortes border border-slate-600 ...">Produto</th>
            <th className="cortes border border-slate-600 ...">Qtd</th>
            <th className="cortes border border-slate-600 ...">Valor</th>
            <th className="cortes border border-slate-600 ...">Status</th>
            <th className="cortes border border-slate-600 ...">Data</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, subIndex) => (
            <tr key={subIndex}>
              <td className="border border-slate-700">{item.nome_produto} {item.tamanho}</td>
              <td className="border border-slate-700">
                {item.quantidadeSelecionada}
              </td>
              <td className="border border-slate-700">R${item.ValorTotal}</td>
              <td className="border border-slate-700">{item.status}</td>
              <td className="border border-slate-700">
                {format(new Date(item.data), "dd/MM/yyyy")}
              </td>
            </tr>
          ))}
           <tr>
            <th colSpan="2"></th>
            <th className="cortes border border-slate-600 ...">
              {" "}
              Total de produtos:
            </th>
            <th className="cortes border border-slate-600 ...">
              {" "}
              {totalProdutos}
            </th>
          </tr>
          <tr>
            <th colSpan="2"></th>
            <th className="cortes border border-slate-600 ...">
              {" "}
              Valor total:
            </th>
            <th className="cortes border border-slate-600 ...">
              {" "}
              R$ {totalValue.toFixed(2)}
            </th>
          </tr>
          <tr>
            <th colSpan="2"></th>
            <th className="cortes border border-slate-600 ...">
              {" "}
              Valor pendente:
            </th>
            <th className="cortes border border-slate-600 ...">
              {" "}
              R$ {(totalValue - totalPago).toFixed(2)}
            </th>
          </tr>
         
        </tbody>
      </table>
    </div>
  );
};

export default ProductTable;
