import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import ProdutoFormUP from "./ProdutoFormUP";
import NavbarOffic from "../componetsNavbar/NavbarOffic";

function EditProduto() {
  const { userId } = useParams();
  const [produtoData, setProdutoData] = useState({
    nome_produto: "",
    tamanho: "",
    preco: "",
    imagem_url: "",
    estoque: "",
    descricao: "",
    usuario_id: 1 // isso e um test
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Hook para navegação

  useEffect(() => {
    async function fetchProdutoData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_HOST_API}/produtos/${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(`Erro ao buscar dados do produto com ID ${userId}`);
        }

        const data = await response.json();
        if (data) {
          setProdutoData(data);
          setLoading(false);
        } else {
          setError("Produto não encontrado");
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setError(error.message || "Erro desconhecido");
        setLoading(false);
      }
    }

    fetchProdutoData();
  }, [userId]);
 
  // Função para lidar com a atualização do produto
  const handleUpdateProduto = async (updatedProdutoData) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST_API}/produtos/${userId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedProdutoData),
        }
      );
      if (!response.ok) {
        throw new Error(
          `Erro ao atualizar os dados do produto com ID ${userId}`
        );
      }
      // Se a atualização for bem-sucedida, você pode redirecionar o usuário ou tomar outra ação
    } catch (error) {
      console.error(error);
      setError(error.message || "Erro desconhecido");
    }
  };

  // Função para lidar com a exclusão do produto
  const handleDelete = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST_API}/produtos/${userId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`Erro ao excluir o produto com ID ${userId}`);
      }
      // Se a exclusão for bem-sucedida, redirecione o usuário para a página de produtos
      navigate("/Produtos");
    } catch (error) {
      console.error(error);
      setError(error.message || "Erro desconhecido");
    }
  };

  return (
    <div>
      {loading ? (
        <p>Carregando...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div>
          <NavbarOffic />
          <div className="flex items-center flex-col">
            <div className="list-reset flex justify-around justify-center py-2 w-full ">
              <ol className="list-reset flex justify-center items-center">
              <li>
                  <Link
                    className="text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <span className="mx-2 text-neutral-500 dark:text-neutral-400">
                    /
                  </span>
                </li>
                <li>
                  <Link
                    to="/produtos"
                    className="text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                  >
                    Produtos
                  </Link>
                </li>
                <li>
                  <span className="mx-2 text-neutral-500 dark:text-neutral-400">
                    /
                  </span>
                </li>
                <li className="text-neutral-500 dark:text-neutral-400">
                  Editar-Produto
                </li>
              </ol>
            </div>
            <ProdutoFormUP
              produtoData={produtoData}
              handleInputChange={(e) => {
                const { name, value } = e.target;
                setProdutoData({
                  ...produtoData,
                  [name]: value,
                });
              }}
              handleImageUrlChange={(newImageUrl) => {
                setProdutoData({
                  ...produtoData,
                  imagem_url: newImageUrl,
                });
              }}
              handleSubmit={handleUpdateProduto}
              handleDelete={handleDelete} // Passar a função handleDelete
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default EditProduto;
