// import React, { createContext, useContext, useState, useEffect } from "react";

// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [userData, setUserData] = useState(
//     JSON.parse(localStorage.getItem("userData")) || null
//   );

//   const isAuthenticated = userData !== null;

//   const login = (userData) => {
//     setUserData(userData);
//   };

//   const logout = () => {
//     setUserData(null);
//     localStorage.removeItem("userData");
//   };

//   // useEffect(() => {
//   //   // Quando o componente for montado, exibe os dados do usuário presentes no localStorage
//   //   console.log('Dados do usuário:', userData.UserType);
//   // }, [userData]);

//   return (
//     <AuthContext.Provider value={{ isAuthenticated, userData, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };
// export const useAuth = () => useContext(AuthContext);
// --------------------------------------------------------------------------------------------------------------------
// import React, { createContext, useContext, useState, useEffect } from "react";

// const AuthContext = createContext();

// export const  AuthProvider = ({ children }) => {
//   const [userData, setUserData] = useState(
//     JSON.parse(localStorage.getItem("userData")) || null
//   );
//   const isAuthenticated = userData !== null;
//   const login = (userData) => {
//     setUserData(userData);
//   };
//   const logout = () => {
//     setUserData(null);
//     localStorage.removeItem("userData");
//   };

//   useEffect(() => {
//     // Exibir dados do usuário quando o componente é montado
//     console.log('Dados do usuário:', userData ? userData.UserType : null);
//   }, [userData]);

//   return (
//     <AuthContext.Provider value={{ isAuthenticated, userData, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => {
//   const context = useContext(AuthContext);

//   if (!context) {
//     throw new Error("useAuth deve ser utilizado dentro de um AuthProvider");
//   }

//   return context;
// };


// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const  AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData")) || null
  );
  const isAuthenticated = userData !== null;
  const login = (userData) => {
    setUserData(userData);
  };
  const logout = () => {
    setUserData(null);
    localStorage.removeItem("userData");
  };

  useEffect(() => {
    console.log('Dados do usuário:', userData ? userData.UserType : null);
  }, [userData]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, userData, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth deve ser utilizado dentro de um AuthProvider");
  }

  return context;
};