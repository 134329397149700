import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import CreatSubmitButton from "../buttons/CreatSubmitButton";
import CreatTextInput from "./CreatTextInput";

function UpdateUserForm({
  userData,
  handleInputChange,
  handleSubmit,
  handleDelete,
}) {
  const [deletionSuccess, setDeletionSuccess] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false); // State for update success

  const navigate = useNavigate();

  const handleDeleteClick = async () => {
    if (window.confirm("Tem certeza de que deseja apagar essa conta?")) {
      try {
        await handleDelete();
        setDeletionSuccess(true);
        setTimeout(() => {
          navigate("/clientes");
        }, 2000);
      } catch (error) {
        console.error("Error deleting account:", error);
      }
    }
  };

  const handleUpdateSuccess = () => {
    setUpdateSuccess(true);
    setTimeout(() => {
      setUpdateSuccess(false);
    }, 2000);
  };

  return (
    <div>
      {deletionSuccess && alert("Conta apagada com sucesso!")}
      {updateSuccess && alert("Conta atualizada com sucesso!")}{" "}
      {/* Display update success alert */}
      <form
        onSubmit={(e) => {
          handleSubmit(e);
          handleUpdateSuccess();
        }}
        className="lg:m-28 lg:h-3/5 lg:m-4 justify-center"
      >
        {/* User input fields */}
        <div className="p-5 border rounded-lg shadow bg-gray-800 border-gray-700">
          <CreatTextInput
            name="nome"
            label="*Login"
            type="text"
            value={userData.nome}
            onChange={handleInputChange}
            required
          />
          <CreatTextInput
            name="observacao"
            label="*Nome completo (observacao)"
            type="text"
            value={userData.observacao}
            onChange={handleInputChange}
            required
          />
          <CreatTextInput
            name="email"
            label="*Email"
            type="email"
            value={userData.email}
            onChange={handleInputChange}
            required
          />
          <div className="grid md:grid-cols-2 md:gap-6">
            <CreatTextInput
              name="senha"
              label="*Senha"
              type="password"
              value={userData.senha}
              onChange={handleInputChange}
              required
            />

            <CreatTextInput
              name="telefone"
              label="*Telefone"
              type="text"
              value={userData.telefone}
              onChange={handleInputChange}
              required
            />

            <CreatTextInput
              name="endereco"
              label="*Endereco"
              type="text"
              value={userData.endereco}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="flex justify-between mt-6">
            <div>
              <CreatSubmitButton
                text="Atualizar Cadastro"
                className=" w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>
            <div>
              <button
                type="button"
                onClick={handleDeleteClick}
                className="mt-4 mx-auto flex m-2 text-white  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-red-600 hover:bg-red-700 focus:ring-red-800"
              >
                EXCLUIR
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default UpdateUserForm;
