import React, { useState, useEffect, useCallback } from "react";

const CartTable = () => {
  const [cartData, setCartData] = useState([]);
  const [userData, setUserData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    setUserData(storedUserData);

    const storedCartData = JSON.parse(localStorage.getItem("carrinho"));
    setCartData(storedCartData || []);

    // Atualizar o carrinho a cada 5 segundos
    const intervalId = setInterval(() => {
      const updatedCartData =
        JSON.parse(localStorage.getItem("carrinho")) || [];
      setCartData(updatedCartData);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleSubmit = async () => {
    try {
      for (const item of cartData) {
        const pedidoData = {
          nome_cliente: userData.nome,
          status: "Aguardando",
          usuario_id: userData.id,
          produto_id: item.produto_id,
          nome_produto: item.nome_produto,
          tamanho: item.tamanho,
          preco: item.preco * item.quantidadeSelecionada,
          estoque: item.estoque,
          descricao: item.descricao,
          quantidadeSelecionada: item.quantidadeSelecionada,
        };

        console.log("Enviando pedido:", pedidoData);

        const response = await fetch(
          `${process.env.REACT_APP_HOST_API}/pedidos`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(pedidoData),
          }
        );

        if (!response.ok) {
          throw new Error("Erro ao enviar dados");
        }

        const estoqueUpdateData = {
          estoque: item.estoque - item.quantidadeSelecionada,
        };

        console.log("Atualizando estoque:", estoqueUpdateData);

        const responseProduto = await fetch(
          `${process.env.REACT_APP_HOST_API}/produtosEstoque/${item.produto_id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(estoqueUpdateData),
          }
        );

        if (!responseProduto.ok) {
          throw new Error("Erro ao atualizar estoque do produto");
        }
      }

      setCartData([]);
      alert("Seu pedido foi enviado com sucesso👌");
    } catch (error) {
      console.error("Erro ao enviar dados: ", error);
    }
  };

  const handleConfirmClick = async () => {
    try {
      setIsSubmitting(true);
      await handleSubmit();
      localStorage.removeItem("carrinho");
    } catch (error) {
      console.error("Erro ao confirmar pedido: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRemoveClick = useCallback(
    async (productId) => {
      try {
        const updatedCartData = cartData.filter(
          (item) => item.produto_id !== productId
        );

        // Atualizar os dados do carrinho no localStorage após a remoção
        localStorage.setItem("carrinho", JSON.stringify(updatedCartData));

        // Restante do código permanece inalterado
      } catch (error) {
        console.error("Erro ao remover produto: ", error);
      }
    },
    [cartData]
  );

  const groupItems = () => {
    const groupedItems = {};

    cartData.forEach((item) => {
      const { produto_id, nome_produto, preco, quantidadeSelecionada } = item;

      const key = `${produto_id}-${nome_produto}`;

      if (!groupedItems[key]) {
        groupedItems[key] = {
          produto_id,
          nome_produto,
          preco,
          quantidadeTotal: quantidadeSelecionada,
          valorTotal: quantidadeSelecionada * preco,
        };
      } else {
        groupedItems[key].quantidadeTotal += quantidadeSelecionada;
        groupedItems[key].valorTotal += quantidadeSelecionada * preco;
      }
    });

    return Object.values(groupedItems);
  };

  return (
    <table className="Fomte10px bg-white border border-gray-200 CorAZulFundo lg:text-xs flex lg:flex-wrap items-center flex-col">
      <thead className="text-gray-700 w-full lg:w-full uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr className="sm:py-2 sm:px-28">
          <th
            scope="col"
            className="lg:px-5 px-3 lg:py-2 py-2 sm:px-11 sm:py-3"
          >
            Produto
          </th>
          <th
            scope="col"
            className="lg:px-10 px-6 lg:py-2 py-2 sm:px-14 sm:py-3"
          >
            Quantidade
          </th>
          <th
            scope="col"
            className="lg:px-2 px-3 lg:py-2 py-2 sm:px-16 sm:py-3"
          >
            Valor
          </th>
          <th
            scope="col"
            className="lg:px-10 px-2 lg:py-2 py-2 sm:px-16 sm:py-3"
          >
            Remover
          </th>
        </tr>
      </thead>
      <tbody>
        {groupItems().map((item, index) => (
          <tr
            key={index} // Usando o índice como chave (certifique-se de que cada índice seja único)
            className="border-b dark:bg-gray-800 dark:border-gray-700 lg:text-sm text-center"
          >
            <td className="lg:px-5 lg:py-2 py-2 sm:px-11 sm:py-3">
              {item.nome_produto}
            </td>
            <td className="lg:px-8 px-12 lg:py-2 py-2 sm:px-14 sm:py-3">
              <h1>{item.quantidadeTotal}</h1>
            </td>
            <td className="lg:px-3 px-2 lg:py-4 py-4 sm:px-16 sm:py-3 font-semibold text-gray-900 dark:text-white">
              R${item.valorTotal.toFixed(2)}
            </td>
            <td className="lg:px-3 px-2 lg:py-4 py-4 sm:px-16 sm:py-3">
              <button
                className="font-medium text-red-600 dark:text-red-500 hover:underline"
                onClick={() => handleRemoveClick(item.produto_id)}
              >
                Remover
              </button>
            </td>
          </tr>
        ))}
        <tr className="text-sm text-center">
          <td colSpan="4">
            <p className="mt-2">
              R$
              {cartData
                .reduce(
                  (total, item) =>
                    total + item.quantidadeSelecionada * item.preco,
                  0
                )
                .toFixed(2)}
            </p>
            <button className="m-1" onClick={handleConfirmClick}>
              {isSubmitting ? (
                <svg
                  className="animate-spin h-5 w-5 mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM20 12c0-3.042-1.135-5.824-3-7.938l-3 2.647A7.962 7.962 0 0116 12h4z"
                  ></path>
                </svg>
              ) : (
                <span className="uppercase inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                  Confirmar
                </span>
              )}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CartTable;