import React from 'react'
import HistoricoVendas from '../components/componetsHistorico/historicoVendas/HistoricoVendas'
import NavbarOffic from '../components/componetsNavbar/NavbarOffic'

const TelaHistoricoVendas = () => {
  return (
    <div>
      <NavbarOffic/>
      <HistoricoVendas/>

    </div>
  )
}

export default TelaHistoricoVendas