import React from "react";
import PedidoItem from "./PedidoItem";

const PedidoDetalhes = ({ orders }) => {
  // Calcular o total de produtos
  const totalProdutos = orders.reduce((acc, order) => acc + (order.quantidadeSelecionada || 0), 0);

  return (
    <div className="p-2">
      <table className="w-full border-collapse border border-slate-500">
        <thead>
          <tr>
            <th className="cortes border border-slate-600">Produto</th>
            <th className="cortes border border-slate-600">Tamanho</th>
            <th className="cortes border border-slate-600">Quantidade</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <PedidoItem key={order.id} order={order} />
          ))}
        </tbody>
      </table>
      <div className="mt-4 text-white">
        Total de produtos: {totalProdutos}
      </div>
    </div>
  );
};

export default PedidoDetalhes;
