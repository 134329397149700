import React, { useState, useEffect, useRef } from "react";

import SidebarCard from "./SidebarCard";

// SVG para o ícone de abertura/fechamento da Sidebar
const sidebarIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="37"
    viewBox="0 -960 960 960"
    width="27"
    fill="white"
  >
    <path d="M120-80v-80h720v80H120Zm0-240v-80h720v80H120Zm0-240v-80h720v80H120Zm0-240v-80h720v80H120Z" />
  </svg>
);
const Sidebar = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  // Referência para a div da Sidebar
  const sidebarRef = useRef(null);

  // UseEffect para adicionar ouvinte de clique ao documento
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarVisible(false);
      }
    };

    // Adicionar ouvinte de clique ao documento
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remover ouvinte de clique ao desmontar o componente
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      {/* Botão para abrir/fechar o sidebar */}
      <div className={`ml-${sidebarVisible ? "60" : "0"} p-4 relative`}>
        <button onClick={toggleSidebar} className="">
          {sidebarIcon}
        </button>
      </div>

      {/* Sidebar */}
      {sidebarVisible && (
        <div
          ref={sidebarRef}
          className="CorAZulFundo fixed top-0 left-0 h-screen w-60 bg-white shadow z-50"
        >
          <SidebarCard />
        </div>
      )}
    </div>
  );
};

export default Sidebar;
