import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
import LogoComponent from "../componetsNavbar/LogoComponent";
import SidebarItem from "./SidebarItem";
import IconHome from "../componetsIcons/IconHome";
import IconStore from "../componetsIcons/IconStore";
import IconUser from "../componetsIcons/IconUser";
import IconItens from "../componetsIcons/IconItens";
import IconHistorico from "../componetsIcons/IconHistorico";
import IconSair from "../componetsIcons/IconSair";
import IconFinanceiro from "../componetsIcons/IconFinanceiro";

function SidebarCard() {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [userType, setUserType] = useState("");

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      setUserType(userData.UserType);
    }
  }, []);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <div>
      <nav className={`CorAZulFundo mt-4 ${sidebarVisible ? "ml-60" : ""}`}>
        <div>
          <LogoComponent />
          <div
            className="flex flex-col flex-wrap items-center text-white rounded-md shadow-sm m-3"
            role="group"
          >
            <SidebarItem to="/loja" label="Loja" icon={<IconStore />} />

            {userType === "loja" && (
              <>
                <SidebarItem to="/home" label="Home" icon={<IconHome />} />
                <SidebarItem
                  to="/Clientes"
                  label="Clientes"
                  icon={<IconUser />}
                />
                <SidebarItem
                  to="/Produtos"
                  label="Produto"
                  icon={<IconItens />}
                />
                <SidebarItem
                  to="/telapedidos"
                  label="Pedidos"
                  icon={<IconStore />}
                />
                <SidebarItem
                  to="/Financeiro"
                  label="Financeiro"
                  icon={<IconFinanceiro />}
                />
                <SidebarItem
                  to="/TelaHistoricoVendas"
                  label="Historico Vendas"
                  icon={<IconHistorico />}
                />
              </>
            )}
            <SidebarItem
              to="/TelaHistoricoPedidos"
              label="Historico"
              icon={<IconHistorico />}
            />
            <div className="mt-5">
              <SidebarItem to="/" label="Sair" icon={<IconSair />} />
            </div>
          </div>
        </div>
        <div className="text-white m-2 Fomte13px lg:mt-10">
          <p>Contato:</p>
          <div className="flex items-center mt-3">
            <span>(92) 9 99114-3062</span>
          </div>
          <div className="flex items-center mt-3">
            <span>yobom.adm.fm@gmail.com</span>
          </div>
          <div className="flex items-center mt-3">
            <span>versão (BETA)</span>
          </div>
        </div>
      </nav>
      {sidebarVisible && (
        <button
          onClick={toggleSidebar}
          className="fixed top-4 left-4 bg-blue-500 px-2 py-1 rounded-lg text-white"
        ></button>
      )}
    </div>
  );
}

export default SidebarCard;
