import React, { useState } from "react";
import UserForm from "../components/componetsUsuarioClientes/UserForm";
import NavbarOffic from "../components/componetsNavbar/NavbarOffic";
import { Link } from "react-router-dom";

const CreateUsuarios = () => {
  const initialUserData = {
    nome_cliente: "",
    email: "",
    senha: "",
    telefone: "",
    endereco: "",
    observacao: "",
    data_criacao: "",
    UserType: "cliente",
  };

  const [userData, setUserData] = useState(initialUserData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !userData.nome_cliente ||
      !userData.senha ||
      !userData.UserType ||
      !userData.email ||
      !userData.telefone ||
      !userData.endereco
    ) {
      alert("Por favor preencha todos os campos obrigatórios.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST_API}/clientes`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      if (response.status === 201) {
        alert("Cliente cadastrado com sucesso!");
        setUserData(initialUserData); // Redefinir os campos para o estado inicial
      } else {
        alert("Erro ao cadastrar o cliente.");
      }
    } catch (error) {
      console.error("Erro ao cadastrar o cliente:", error);
    }
  };

  return (
    <div className="h-screen ">
      <NavbarOffic />
      <div className="py-4 w-full flex justify-center shadow">
        <ol className="list-reset flex text-white">
          <li>
            <Link
              to="/"
              className="transition duration-150 ease-in-out hover:text-blue-500 focus:text-blue-500"
            >
              Home
            </Link>
          </li>
          <li>
            <span className="mx-2">/</span>
          </li>
          <li>
            <Link
              to="/Clientes"
              className="transition duration-150 ease-in-out hover:text-blue-500 focus:text-blue-500"
            >
              Clientes
            </Link>
          </li>
          <li>
            <span className="mx-2">/</span>
          </li>
          <li className="text-gray-800">Cadastrar Clientes</li>
        </ol>
      </div>
      <div className="flex items-center justify-center mt-8">
        <UserForm
          userData={userData}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default CreateUsuarios;
