import React from "react";
import { Link } from "react-router-dom";

function SidebarItem({ to, label, icon }) {
  return (
    <Link to={to}>
      <button
        type="button"
        className="inline-flex items-center px-4 lg:px-7 py-4 text-sm font-medium focus:z-10 focus:ring-2 focus:ring-gray-500 focus:text-white border-white text-white hover:text-white hover:bg-gray-700 focus:bg-gray-700"
      >
        {icon}
        {label}
      </button>
    </Link>
  );
}

export default SidebarItem;
