import React, { useState, useEffect } from "react";

const ProdutosTab = ({ onProdutoChange }) => {
  const [produtosList, setProdutosList] = useState([]);

  const fetchProdData = async () => {
    try {
      const produtosResponse = await fetch(
        `${process.env.REACT_APP_HOST_API}/produtos`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!produtosResponse.ok) {
        throw new Error("Failed to fetch users");
      }

      const produtoData = await produtosResponse.json();
      setProdutosList(produtoData);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchProdData();
  }, []);

  useEffect(() => {
    onProdutoChange(produtosList);
  }, [onProdutoChange, produtosList]);

  return (
    <div className="testHome07  lg:w-96 w-full  mt-1 h-72  p-3.5 shadow-xl CorAZulFundo overflow-auto lg:rounded-2xl border-solid border-2 border-black ... ">
      <div>
        <p>Quantidade de Produtos: {produtosList.length}</p>

        <table className="w-full   border-collapse   border border-slate-500 ...">
          <thead>
            <tr>
              <th className="cortes border border-slate-600 ...">Produtos</th>
              <th className="cortes border border-slate-600 ...">Estoque</th>
            </tr>
          </thead>
          <tbody className="testtexto">
            {produtosList.map((produtos) => (
              <tr key={produtos.id}>
                <td className="border border-slate-700 ...">
                  {produtos.nome_produto}
                </td>
                <td className="border border-slate-700 ...">
                  {produtos.estoque}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProdutosTab;
