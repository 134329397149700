import React from "react";
import LojaComponets from "../components/componetsLoja/LojaComponets";
import Navbar from "../components/componetsNavbar/Navbar";
const Loja = () => {
  return (
    <div>
      <Navbar />
      <LojaComponets />
    </div>
  );
};

export default Loja;
