import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import UpdateUserForm from "../components/componetsUsuarioClientes/UpdateUserForm";
import NavbarOffic from "../components/componetsNavbar/NavbarOffic";

function UpdateUsuario() {
  const { userId } = useParams();
  const [userData, setUserData] = useState({
    nome: "",
    email: "",
    senha: "",
    telefone: "",
    endereco: "",
    observacao: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_HOST_API}/usuarios/${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(`Erro ao buscar dados do usuário com ID ${userId}`);
        }

        const data = await response.json();
        if (Array.isArray(data) && data.length > 0) {
          setUserData(data[0]);
          setLoading(false);
        } else {
          setError("Usuário não encontrado");
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setError(error.message || "Erro desconhecido");
        setLoading(false);
      }
    }

    fetchUserData();
  }, [userId]);

  // Função para lidar com a atualização do usuário
  const handleUpdateUser = async (updatedUserData) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST_API}/usuarios/${userId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedUserData),
        }
      );
      if (!response.ok) {
        throw new Error(
          `Erro ao atualizar os dados do usuário com ID ${userId}`
        );
      }
      // Se a atualização for bem-sucedida, você pode redirecionar o usuário ou tomar outra ação
    } catch (error) {
      console.error(error);
      setError(error.message || "Erro desconhecido");
    }
  };

  // Função para lidar com a exclusão do usuário
  const handleDelete = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST_API}/usuarios/${userId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`Erro ao excluir a conta do usuário com ID ${userId}`);
      }
      // Se a exclusão for bem-sucedida, você pode redirecionar o usuário ou tomar outra ação
    } catch (error) {
      console.error(error);
      setError(error.message || "Erro desconhecido");
    }
  };

  return (
    <div>
      <NavbarOffic />
      {loading ? (
        <p>Carregando...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div>
          <div className="py-4 w-full flex justify-center shadow">
            <ol className="list-reset flex text-white">
              <li>
                <Link
                  to="/"
                  className="transition duration-150 ease-in-out hover:text-blue-500 focus:text-blue-500"
                >
                  Home
                </Link>
              </li>
              <li>
                <span className="mx-2">/</span>
              </li>
              <li>
                <Link
                  to="/Clientes"
                  className="transition duration-150 ease-in-out hover:text-blue-500 focus:text-blue-500"
                >
                  Clientes
                </Link>
              </li>
              <li>
                <span className="mx-2">/</span>
              </li>
              <li className="text-gray-800">Atualizar Cadastro</li>
            </ol>
          </div>
          <div className="flex items-center justify-center mt-8">
            <UpdateUserForm
              userData={userData}
              handleDelete={handleDelete} // Passa a função handleDelete para o componente
              handleInputChange={(e) =>
                setUserData({
                  ...userData,
                  [e.target.name]: e.target.value,
                })
              }
              handleSubmit={() => handleUpdateUser(userData)}
              handleCancel={() => {
                // Implemente a ação de cancelamento, como redirecionar de volta à visualização de detalhes do usuário
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default UpdateUsuario;
