import React from "react";
import IconData from "../../componetsIcons/IconData";

const DayOrders = ({ day, orders }) => {
  // Função para calcular o preço total
  const calcularPrecoTotal = () => {
    return orders.reduce((total, order) => total + parseFloat(order.preco), 0);
  };

  // Função para agrupar pedidos por cliente e data
  const agruparPedidos = () => {
    return orders.reduce((acc, order) => {
      const key = `${order.nome_cliente}-${order.data}`;
      if (!acc[key]) {
        acc[key] = {
          ...order,
          quantidadeTotal: 0,
          precoTotal: 0
        };
      }
      acc[key].quantidadeTotal += order.quantidadeSelecionada * (order.itens ? order.itens.length : 1);
      acc[key].precoTotal += parseFloat(order.preco) * order.quantidadeSelecionada;
      return acc;
    }, {});
  }; 

  const pedidosAgrupados = Object.values(agruparPedidos());

  return (
    <div className="w-full text-white md:text-base text-xs CorAZulFundo mb-4">
      <div className="justify-evenly m-3 flex">
        <span className="text-lg flex text-justify font-bold">
          <span className="m-1">
            <IconData />
          </span>
          {day}
        </span>
      </div>

      <table className="w-full mb-2 border-collapse border border-slate-500">
        <thead>
          <tr>
            <th className="cortes border border-slate-600">Cliente</th>
            <th className="cortes border border-slate-600">Status</th>
            <th className="cortes border border-slate-600">Qtd</th>
            <th className="cortes border border-slate-600">Preço Total</th>
          </tr>
        </thead>
        <tbody className="text-white">
          {pedidosAgrupados.map((order, index) => (
            <tr key={index}>
              <td className="border border-slate-700">{order.nome_cliente}</td>
              <td className="border border-slate-700">{order.status}</td>
              <td className="border border-slate-700">{order.quantidadeTotal}</td>
              <td className="border border-slate-700">R${order.precoTotal.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="3" className="text-right font-bold border border-slate-600">
              Preço Total:
            </td>
            <td className="border border-slate-600">
              R${calcularPrecoTotal().toFixed(2)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default DayOrders;
