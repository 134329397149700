import React from "react";

function ImageInput({ imageUrl, onImageUrlChange }) {
  return (
    <div>
      <img
        className="border border-gray-200 rounded-lg dark:border-gray-700 h-72 w-full"
        src={
          imageUrl
            ? imageUrl
            : "https://www2.camara.leg.br/atividade-legislativa/comissoes/comissoes-permanentes/cindra/imagens/sem.jpg.gif"
        }
        alt=""
      />
    </div>
  );
}

export default ImageInput;
