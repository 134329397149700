import React from "react";

const SearchBar = ({ searchTerm, onSearchTermChange }) => {
  return (
    <div className="p-5">
      <input
        type="text"
        placeholder="Buscar cliente"
        value={searchTerm}
        onChange={(e) => onSearchTermChange(e.target.value)}
        className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
      />
    </div>
    
     
  );
};

export default SearchBar;
