import React from "react";
import HistoricoPedidos from "../components/componetsHistorico/historicoPedidos/HistoricoPedidos";
import NavbarOffic from "../components/componetsNavbar/NavbarOffic";

const TelaHistoricoPedidos = () => {
  return (
    <div>
      <NavbarOffic />
      <HistoricoPedidos />
    </div>
  );
};

export default TelaHistoricoPedidos;
