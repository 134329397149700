import React from "react";
import { format } from "date-fns";

const IndividualOrder = ({ order }) => {
  return (
    <tr key={order.id}>
      <td className="border border-slate-700 ...">
        {format(new Date(order.data), "dd/MM/yyyy")}
      </td>
      <td className="border border-slate-700 ...">{order.status}</td>
      <td className="border border-slate-700 ...">{order.nome_produto} {order.tamanho}</td>
      <td className="border border-slate-700 ...">
        {order.quantidadeSelecionada}x
      </td>
      <td className="border border-slate-700 ...">
        R${(order.preco)}
      </td>
    </tr>
  );
};

export default IndividualOrder;
