import React, { useState } from "react";
import LogoComponent from "./LogoComponent";
import CartButton from "../componetsCarrinho/CartButton";
import CartTable from "../componetsCarrinho/CartTable";
import Sidebar from "../componetsSidebar/Sidebar";

const Navbar = ({ handleQuantityChange }) => {
  const [produtos, setProdutos] = useState([]);
  const [isListaVisivel, setIsListaVisivel] = useState(false);

  const toggleLista = () => {
    setIsListaVisivel(!isListaVisivel);
  };

  const removerProdutoDoCarrinho = (produtoId) => {
    // Aqui você pode adicionar a lógica para remover o produto do carrinho, se necessário
    const novoCarrinho = produtos.filter(produto => produto.id !== produtoId);
    setProdutos(novoCarrinho);
  };
  

  return (
    <div>
      <nav className="bg-gray-800 border-gray-700">
        <div className=" max-w-screen-full flex flex-wrap items-center justify-between mx-auto p-2 mr-5 ml-5 ">
          <div className="items-center flex flex-wrap inline-flex rounded-md shadow-sm m-1">
            <Sidebar />
            <LogoComponent />
          </div>
          <div className="relative w-11/12 lg:w-32 m-2">
            <CartButton toggleLista={toggleLista} />
            {isListaVisivel && (
              <div className="absolute text-white right-0 mt-5 justify-between items-center h-4/5 w-full lg:w-96  bg-white shadow">
                <CartTable
                  produtos={produtos}
                  handleQuantityChange={handleQuantityChange}
                  removerProdutoDoCarrinho={removerProdutoDoCarrinho}
                />
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
