import React, { useState } from "react";
import NavbarOffic from "../components/componetsNavbar/NavbarOffic";
import ProdutosTab from "../components/componetsHome/ProdutosTab";
import PedidosTab from "../components/componetsHome/PedidosTab";
import UsuariosTab from "../components/componetsHome/UsuariosTab";
// import VendasTab from "../components/componetsHome/VendasTab";
import CaixaTab from "../components/componetsHome/CaixaTab";

const Home = () => {
  const [totalCaixa, setTotalCaixa] = useState(0);
  const [totalProdutos, setTotalProdutos] = useState(0);

  const handleProdutoChange = (produtos) => {
    setTotalProdutos(produtos.length);
  };

  // const handleTotalChange = (total) => {
  //   setTotalVendas(total);
  // };
  const handleTotalCaixaChange = (total) => {
    setTotalCaixa(total);
  };
  return (
    <div>
      <NavbarOffic />
      <div className="lg:mt-2.5 flex items-center justify-center lg:text-base text-xs text-white">
        <div className="w-full lg:11/12  flex flex-col items-center">
          <div className="w-full p-0 lg:p-4 flex flex-wrap justify-around">
            {/* <div className="hadow-xl CorAZulFundo p-2 w-full lg:w-72 m-0.5 lg:m-1 lg:rounded-2xl flex items-center flex-col justify-center">
              <p>Total de divida R${totalVendas.toFixed(2)}</p>
            </div> */}
            <div className="shadow-xl CorAZulFundo p-2 m-0.5 lg:m-1  w-full lg:w-72 lg:rounded-2xl flex items-center flex-col justify-center">
            <p>Valor em Caixa R${totalCaixa.toFixed(2)}</p>

            </div>
            <div className="shadow-xl CorAZulFundo p-2 m-0.5 lg:m-1  w-full lg:w-72 lg:rounded-2xl flex items-center flex-col justify-center">
              <p>Quantidade de Produtos: {totalProdutos}</p>
            </div>
          </div>

          <div className=" w-full flex flex-wrap justify-around">
            {/* vendas */}
            {/* <VendasTab onTotalChange={handleTotalChange} /> */}
            {/* Caixa */}
            <CaixaTab  onTotalChange={handleTotalCaixaChange}/>
            {/*Usuários */}
            <UsuariosTab />
          </div>
          <div className=" w-full mt-1  lg:p-3 md:p-0 flex  flex-wrap justify-around ">
            {/* Pedidos */}
            <PedidosTab />
            {/* PRODUTOS */}
            <ProdutosTab onProdutoChange={handleProdutoChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
