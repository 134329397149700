import React, { useState } from "react";
import { format } from "date-fns";
import IconUser from "../componetsIcons/IconUser";
import IconCar from "../componetsIcons/IconCar";
import IconData from "../componetsIcons/IconData";
import PedidoDetalhes from "./PedidoDetalhes";
import IconConfirm from "../componetsIcons/IconConfirm";

const enviarDadosParaHistoricoEVenda = async (pedido, status) => {
  try {
    // Atualize o status para o status fornecido (pode ser "Finalizado", "Em Aberto" ou "Falta Pagamento")
    const pedidoAtualizado = { ...pedido, status };

    // Faça a solicitação POST para o histórico com o pedido atualizado
    const responseHistorico = await fetch(
      `${process.env.REACT_APP_HOST_API}/historico`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(pedidoAtualizado),
      }
    );

    if (!responseHistorico.ok) {
      throw new Error("Falha ao enviar dados para o histórico");
    }

    // Faça a solicitação POST para o vendas com o pedido atualizado
    const responseVendas = await fetch(
      `${process.env.REACT_APP_HOST_API}/vendas`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(pedidoAtualizado),
      }
    );

    if (!responseVendas.ok) {
      throw new Error("Falha ao enviar dados para o vendas");
    }

    console.log("Dados enviados para o histórico e vendas com sucesso!");
  } catch (error) {
    console.error(
      "Erro ao enviar dados para o histórico e vendas:",
      error.message
    );
  }
};

const PedidoGroupConfirmado = ({
  vendas,
  orders,
  index,
  expanded,
  toggleUserOrders,
  handleConfirmarClick,
  handleDeleteClick,
}) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const handleConfirmarPedido = async (pedidoId) => {
    try {
      setIsConfirming(true);
      await enviarDadosParaHistoricoEVenda(pedidoId, "Em Aberto");
    } catch (error) {
      console.error("Erro ao confirmar pedido: ", error);
    } finally {
      setIsConfirming(false);
    }
  };

  return (
    <div className="flex flex-wrap flex-row items-start w-full md:w-3/12 text-xs text-white">
      <div className="w-full border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 m-1 md:m-3">
        <div
          className="p-1 inline-block w-full overflow-x-auto"
          onClick={() => toggleUserOrders(orders[0].id)}
          style={{ cursor: "pointer" }}
        >
          <div className="flex justify-between items-center border-b">
            <div className="w-full flex justify-around mb-2 mt-2">
              <div>
                <p className="text-white text-sm font-light flex items-center">
                  <span className="mr-1">
                    <IconUser />
                  </span>
                  {orders[0].nome_cliente}
                </p>
              </div>
              <div>
                <p className="text-white flex text-sm font-light">
                  <span className="mr-1">
                    <IconCar />
                  </span>{" "}
                  {orders[0].status}
                </p>
                <p className="text-white flex text-sm font-light">
                  <span className="mr-1">
                    <IconData />
                  </span>
                  {format(new Date(orders[0].data_criada), " dd/MM/yyyy")}
                </p>
              </div>
            </div>
          </div>

          {expanded && <PedidoDetalhes orders={orders} />}
        </div>
        <div className="mt-2 m-1 flex justify-around items-center">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
              orders.forEach((pedido) => {
                handleConfirmarClick(pedido.id);
                handleConfirmarPedido(pedido.id); // Chamar a função interna
                enviarDadosParaHistoricoEVenda(pedido, "Em Aberto");
              });
            }}
            disabled={isConfirming} // Desativa o botão enquanto o processo de confirmação estiver ocorrendo
          >
            {isConfirming ? (
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM20 12c0-3.042-1.135-5.824-3-7.938l-3 2.647A7.962 7.962 0 0116 12h4z"
                ></path>
              </svg>
            ) : (
              <IconConfirm />
            )}
            Confirmar
          </button>
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-red-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => handleDeleteClick(orders[0].id)}
          >
            Deletar
          </button>
        </div>
      </div>
    </div>
  );
};

export default PedidoGroupConfirmado;
