import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function ClientesList() {
  const [userData, setUserData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_HOST_API}/usuarios`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => setUserData(data))
      .catch((error) => console.error("Erro ao buscar dados: ", error));
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Lógica de envio do formulário aqui
  };

  // Função para filtrar os clientes com base no termo de busca
  const filteredClients = userData.filter((user) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      (user.observacao && user.observacao.toLowerCase().includes(searchLower)) ||
      (user.telefone && user.telefone.toLowerCase().includes(searchLower))
    );
  });

  return (
    <div>
      {/* Adicione um campo de input para a busca */}
      <div className="p-5">
        <input
          type="search"
          id="default-search"
          className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Buscar clientes por nome ou telefone..."
          required
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="list-reset flex justify-around justify-center py-2 w-full ">
        <ol className="list-reset flex justify-center items-center">
          <li>
            <Link
              to="/home"
              className="transition duration-150 ease-in-out text-primary-400 hover:text-primary-500 focus:text-primary-500 active:text-primary-600"
            >
              Home
            </Link>
          </li>
          <li>
            <span className="mx-2 text-neutral-400">/</span>
          </li>
          <li className="text-neutral-400">Clientes</li>
        </ol>

        <Link to="/CreatUsuarios">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path
                d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"
                fill="white"
              />
            </svg>
            Adicionar Cliente
          </button>
        </Link>
      </div>
      <form
        onSubmit={handleSubmit}
        className="lg:m-4 flex flex-col items-center"
      >
        <div className="w-full sm:w-2/3 lg:w-9/12 border rounded-lg shadow bg-gray-800 border-gray-700">
          <div className="py-1 inline-block w-full sm:px-6 lg:px-8 overflow-x-auto">
            <table className="min-w-full text-white">
              <thead>
                <tr className="border-b text-white">
                  <th className="text-sm text-white font-medium text-gray-900 px-6 py-4 text-left">
                    Ações
                  </th>
                  <th className="border-b text-white text-sm font-medium text-gray-900 px-6 py-4 text-left">
                    Login
                  </th>
                  <th className="border-b text-white text-sm font-medium text-gray-900 px-6 py-4 text-left">
                    Nomes "Observacao"
                  </th>
                  <th className="text-sm text-white font-medium text-gray-900 px-6 py-4 text-left">
                    Telefones
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredClients.map((user) => (
                  <tr className="border-b" key={user.id}>
                    <td className="text-gray-900 font-light px-6 py-4">
                      {/* Use o componente Link para navegar para a rota desejada */}
                      <Link to={`/UpdateUsuario/${user.id}`}>
                        <button
                          type="button"
                          className="inline-flex items-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="15"
                            viewBox="0 -960 960 960"
                            width="15"
                          >
                            <path
                              d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"
                              fill="white"
                            />
                          </svg>
                          Editar
                        </button>
                      </Link>
                    </td>
                    <td className="text-white text-sm px-6 py-4 whitespace-nowrap text-sm font-light text-gray-900">
                      {user.nome}
                    </td>
                    <td className="text-white text-sm px-6 py-4 whitespace-nowrap text-sm font-light text-gray-900">
                      {user.observacao}
                    </td>
                    <td className="text-white text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      {user.telefone}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ClientesList;
