import React from "react";
import ComponetsFinanceiroceiro from "../components/componetsFinanc/ComponetsFinanceiro";
import NavbarOffic from "../components/componetsNavbar/NavbarOffic";
const Financeiro = () => {
  return (
    <div>
      <NavbarOffic />
      <ComponetsFinanceiroceiro />
    </div>
  );
};

export default Financeiro;
