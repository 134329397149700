import React, { useState, useEffect } from "react";
import ProductCard from "../componets-Produtor/ProductCard";

const LojaComponets = () => {
  const [produtos, setProdutos] = useState([]);
  const [userData, setUserData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const userDataFromLocalStorage = JSON.parse(
      localStorage.getItem("userData")
    );

    if (userDataFromLocalStorage) {
      setUserData(userDataFromLocalStorage);
    } else {
      console.error("Dados do usuário não encontrados no localStorage.");
    }

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_HOST_API}/produtos`
        );

        if (!response.ok) {
          console.error(
            "Erro ao buscar produtos:",
            response.status,
            response.statusText
          );

          const errorMessage = await response.text();
          console.error("Detalhes da resposta:", errorMessage);

          throw new Error("Erro na requisição");
        }

        const data = await response.json();
        const produtosComEstoque = data.map((produto) => ({
          ...produto,
          estoqueSelecionada: 0,
        }));
        
        produtosComEstoque.sort((a, b) =>
          a.nome_produto.localeCompare(b.nome_produto)
        );

        setProdutos(produtosComEstoque);
      } catch (error) {
        console.error("Erro ao buscar produtos:", error.message);
      }
    };

    fetchData();
  }, []);

  const handleDecrease = (produtoId) => {
    setProdutos((prevProdutos) =>
      prevProdutos.map((produto) =>
        produto.id === produtoId
          ? {
              ...produto,
              estoqueSelecionada: Math.max(produto.estoqueSelecionada - 1, 0),
            }
          : produto
      )
    );
  };

  const handleQuantityChange = (produtoId, estoque) => {
    setProdutos((prevProdutos) =>
      prevProdutos.map((produto) =>
        produto.id === produtoId
          ? {
              ...produto,
              estoqueSelecionada: Math.max(
                0,
                Math.min(Number(estoque), produto.estoque)
              ),
            }
          : produto
      )
    );
  };

  const handleIncrease = (produtoId) => {
    setProdutos((prevProdutos) =>
      prevProdutos.map((produto) =>
        produto.id === produtoId
          ? {
              ...produto,
              estoqueSelecionada: Math.min(
                produto.estoqueSelecionada + 1,
                produto.estoque
              ),
            }
          : produto
      )
    );
  };

  const handleSubmit = (produto) => {
    const carrinho = JSON.parse(localStorage.getItem("carrinho")) || [];
    carrinho.push({
      usuario_id: userData.id,
      produto_id: produto.id,
      nome_produto: produto.nome_produto,
      preco: produto.preco,
      descricao: produto.descricao,
      tamanho: produto.tamanho,
      estoque: produto.estoque,
      quantidadeSelecionada: produto.estoqueSelecionada,
    });
    localStorage.setItem("carrinho", JSON.stringify(carrinho));
    alert("Seu produto está no Carrinho");

    setProdutos((prevProdutos) =>
      prevProdutos.map((p) =>
        p.id === produto.id ? { ...p, estoqueSelecionada: 0 } : p
      )
    );
  };

  const filteredProducts = produtos
    .filter((produto) =>
      produto.nome_produto.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => a.nome_produto.localeCompare(b.nome_produto));

  return (
    <div className="container mx-auto p-5">
      <div className="mb-5">
        <input
          type="search"
          id="default-search"
          className="block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Buscar produtos..."
          required
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="flex flex-wrap justify-center">
        {filteredProducts.map((produto) => (
          <ProductCard
            key={produto.id}
            produto={produto}
            handleDecrease={handleDecrease}
            handleQuantityChange={handleQuantityChange}
            handleIncrease={handleIncrease}
            adicionarProdutoAoCarrinho={() => handleSubmit(produto)}
          />
        ))}
      </div>
    </div>
  );
};

export default LojaComponets;
