import React from 'react'
import ProdutosList from '../components/componets-Produtor/ProdutosList'
import NavbarOffic from '../components/componetsNavbar/NavbarOffic'

const Produtos = () => {
  return (
    <div>
      <NavbarOffic />
      <ProdutosList /></div>
  )
}

export default Produtos