import React, { useEffect } from "react";
import CreatSubmitButton from "../buttons/CreatSubmitButton";
import CreatTextInput from "./CreatTextInput";

function UserForm({ userData, handleInputChange, handleSubmit }) {
  useEffect(() => {
    const generateAutomaticName = () => {
      const firstName = userData.observacao
        ? userData.observacao.split(" ")[0]
        : "";
      const randomNumber = Math.floor(1000 + Math.random() * 9000);
      return `${firstName}@${randomNumber}`;
    };

    if (!userData.nome_cliente && userData.observacao) {
      handleInputChange({
        target: { name: "nome_cliente", value: generateAutomaticName() },
      });
    }
  }, [userData.observacao, userData.nome_cliente, handleInputChange]);

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full max-w-lg p-2 CorAZulFundo border-gray-200 rounded-lg shadow-md"
    >
      <div className="p-2 border rounded-lg shadow ">
        <CreatTextInput
          name="nome_cliente"
          label="*Login"
          value={userData.nome_cliente}
          onChange={handleInputChange}
          required
        />
        <CreatTextInput
          name="observacao"
          label="Nome completo"
          type="text"
          value={userData.observacao}
          onChange={handleInputChange}
          required
        />
        <CreatTextInput
          name="email"
          label="*Email"
          type="email"
          value={userData.email}
          onChange={handleInputChange}
          required
        />
        <div className="grid md:grid-cols-2 md:gap-6">
          <CreatTextInput
            name="senha"
            label="*Senha"
            type="password"
            value={userData.senha}
            onChange={handleInputChange}
            required
          />
          <CreatTextInput
            name="telefone"
            label="*Telefone"
            type="text"
            value={userData.telefone}
            onChange={handleInputChange}
            required
          />
          <CreatTextInput
            name="endereco"
            label="*Endereço"
            type="text"
            value={userData.endereco}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mt-8">
          <CreatSubmitButton text="REGISTRAR CLIENTE" />
        </div>
      </div>
    </form>
  );
}

export default UserForm;
