
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CreatTextInput from "../componetsUsuarioClientes/CreatTextInput";
import ImageInput from "./cardProduto/ImageInput";
import CreatSubmitButton from "./cardProduto/CreatSubmitButton";

const ProdutoFormUP = ({
  produtoData,
  handleInputChange,
  handleImageUrlChange,
  handleSubmit,
  handleDelete, // Adicionando a função handleDelete
}) => {
  // Estado para gerenciar o sucesso da exclusão
  const [deletionSuccess, setDeletionSuccess] = useState(false);

  // Utilize o hook useNavigate para navegar entre as rotas
  const navigate = useNavigate();

  // Função para lidar com o clique no botão de exclusão
  const handleDeleteClick = async () => {
    if (window.confirm("Tem certeza de que deseja apagar esse produto?")) {
      try {
        await handleDelete(); // Chama a função handleDelete passada como prop
        setDeletionSuccess(true);
        setTimeout(() => {
          setDeletionSuccess(false);
          navigate("/Produtos");
        }, 2000);
      } catch (error) {
        console.error("Erro ao deletar produto:", error);
      }
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(produtoData);
      }}
      className="w-full max-w-lg p-6 CorAZulFundo border border-gray-200 rounded-lg shadow-md"
    >
      {/* Renderizar a imagem */}
      <ImageInput
        imageUrl={produtoData.imagem_url}
        onImageUrlChange={handleImageUrlChange}
      />

      {/* Campos de texto */}
      <CreatTextInput
        name="nome_produto"
        label="Nome do produto"
        value={produtoData.nome_produto}
        onChange={handleInputChange}
        required
      />
      <CreatTextInput
        name="imagem_url"
        label="URL da Imagem"
        value={produtoData.imagem_url}
        onChange={handleInputChange}
        required
      />
      <div className="grid md:grid-cols-2 md:gap-6">
        <CreatTextInput
          name="preco"
          label="Valor"
          type="number"
          value={produtoData.preco}
          onChange={handleInputChange}
          required
        />
        <CreatTextInput
          name="tamanho"
          label="Tamanho"
          value={produtoData.tamanho}
          onChange={handleInputChange}
          required
        />
        <CreatTextInput
          name="estoque"
          label="Estoque"
          type="number"
          value={produtoData.estoque}
          onChange={handleInputChange}
          required
        />
      </div>
      <CreatTextInput
        name="descricao"
        label="Descrição"
        value={produtoData.descricao}
        onChange={handleInputChange}
        required
      />

      {/* Mostrar mensagem de sucesso se a exclusão for bem-sucedida */}
      {deletionSuccess && (
        <p className="text-green-500 mt-4">Produto apagado com sucesso!</p>
      )}

      {/* Botões de envio e exclusão */}
      <div className="flex justify-between mt-6">
        <div>
          <CreatSubmitButton
            text="SALVAR"
            className=" w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>
        <div>
          <button
            type="button"
            onClick={handleDeleteClick}
            className="mt-4 mx-auto flex m-2 text-white  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-red-600 hover:bg-red-700 focus:ring-red-800"
          >
            EXCLUIR
          </button>
        </div>
      </div>
    </form>
  );
};

export default ProdutoFormUP;