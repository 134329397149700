import React from "react";
import NavbarOffic from "../components/componetsNavbar/NavbarOffic";
import ListaPedidosAguardando from "../components/componetsPedidos/ListaPedidosAguardando";

const TelaPedidos = () => {

  return (
    <div>
      <NavbarOffic />
      <ListaPedidosAguardando />
      
    </div>
  );
};

export default TelaPedidos;
