import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import SearchBar from "./SearchBar";
import DayOrders from "./DayOrders";

const HistoricoVendas = () => {
  const [userList, setUserList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchUserOrders = async () => {
    try {
      const usersResponse = await fetch(
        `${process.env.REACT_APP_HOST_API}/vendasGeral`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!usersResponse.ok) {
        throw new Error("Failed to fetch users");
      }

      const usersData = await usersResponse.json();
      setUserList(usersData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUserOrders();
  }, []);

  const groupOrdersByDay = () => {
    const groupedOrdersByDay = {};

    userList
      .filter((item) =>
        item.nome_cliente.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .forEach((item) => {
        const key = format(new Date(item.data), "dd/MM/yyyy");

        if (!groupedOrdersByDay[key]) {
          groupedOrdersByDay[key] = [];
        }

        groupedOrdersByDay[key].push(item);
      });

    return groupedOrdersByDay;
  };

  const groupedOrdersByDay = groupOrdersByDay();
  const days = Object.keys(groupedOrdersByDay).reverse();

  return (
    <div>
      <SearchBar searchTerm={searchTerm} onSearchTermChange={setSearchTerm} />
      <div className="m-2 flex text-center flex-col items-center ">
        {days.map((day) => (
          <DayOrders key={day} day={day} orders={groupedOrdersByDay[day]} />
        ))}
      </div>
    </div>
  );
};

export default HistoricoVendas;
