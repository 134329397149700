import React from "react";
import ClientesList from "../components/componetsUsuarioClientes/ClientesList";
import NavbarOffic from "../components/componetsNavbar/NavbarOffic";

const Clientes = () => {
  return (
    <div>
      <NavbarOffic />
      <ClientesList />
    </div>
  );
};

export default Clientes;
