// import React from "react";

// const ProductCard = ({
//   produto,
//   handleDecrease,
//   handleQuantityChange,
//   handleIncrease,
//   adicionarProdutoAoCarrinho,
// }) => {
//   const handleAddToCart = () => {
//     if (produto.estoqueSelecionada > 0) {
//       // Verifica se o estoque selecionado é maior que 0
//       adicionarProdutoAoCarrinho(produto);
//       // Após adicionar ao carrinho, resetamos o estoque selecionado para 0
//       handleQuantityChange(produto.id, 0);
//     } else {
//       // Se o estoque selecionado for 0, não faz nada
//       console.log(
//         "Não é possível adicionar ao carrinho. Estoque selecionado é 0."
//       );
//     }
//   };
//   return (
//     <div
//       className={`lg:m-3 m-2 w-full lg:w-64  rounded-lg shadow bg-gray-800 border-gray-700 ${
//         produto.estoque === 0 ? "out-of-stock" : ""
//       }`}
//     >
//       <img
//         className="rounded-t-lg w-full max-h-36"
//         src={produto.imagem_url}
//         alt={produto.nome_produto}
//       />

//       {/* <div className="p-5">
//         <h5 className="mb-2 text-2xl font-bold tracking-tight text-white">
//           {produto.nome_produto}
//         </h5>
//         <p className="mb-3 font-normal text-gray-400">Valor: {produto.preco}</p>
//         <p className="mb-3 font-normal text-gray-400">
//           Tamanho: {produto.tamanho}
//         </p>

//         <p className="mb-3 font-normal text-gray-400 ">
//           {produto.descricao}
//         </p>
//         <p className="mb-3 font-normal text-gray-400">
//           Estoque: {produto.estoque === 0 ? "Esgotado" : produto.estoque}
//         </p>
//       </div> */}
//       {/* <div className="p-5 flex flex-col  justify-between">
//         <div>
//           <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
//             {produto.nome_produto}
//           </h5>
//           <p className="mb-2 text-lg text-gray-700">R${produto.preco}</p>
//           <p className="mb-2 text-gray-600">Tamanho: {produto.tamanho}</p>
//           <p className="mb-4 text-gray-600">{produto.descricao}</p>
//           <p className="mb-4 text-gray-600">
//             Estoque: {produto.estoque === 0 ? "Esgotado" : produto.estoque}
//           </p>
//         // </div> */}
//       </div>
//       <table className="table-auto w-full text-xs text-sm text-gray-400">
//         <thead className=" uppercase bg-gray-700 text-gray-400">
//           <tr>
//             <th scope="col" className="px-2 py-3">
//               estoque
//             </th>
//             <th scope="col" className="px-2 py-3">
//               Valor
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr className="text-sm  uppercase border-b bg-gray-800 border-gray-700 hover:bg-gray-600">
//             <td className="lg:px-1 items-center lg:py-4">
//               {/*  */}
//               <div className="flex items-center justify-center space-x-3">
//                 <button
//                   className="inline-flex items-center justify-center p-2 text-sm font-medium border rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 bg-gray-200 text-gray-600 hover:bg-gray-300"
//                   type="button"
//                   onClick={() => handleDecrease(produto.id)}
//                 >
//                   <span className="sr-only">Diminuir</span>
//                   <svg
//                     className="w-2 h-2"
//                     aria-hidden="true"
//                     xmlns="http://www.w3.org/2000/svg"
//                     fill="none"
//                     viewBox="0 0 18 2"
//                   >
//                     <path
//                       stroke="currentColor"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                       strokeWidth="2"
//                       d="M1 1h16"
//                     />
//                   </svg>
//                 </button>
//                 <div className="">
//                   <input
//                     type="number"
//                     id="first_product"
//                     className=" text-center w-14 border text-sm rounded-lg block px-2.5 py-1 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
//                     placeholder="0"
//                     value={produto.estoqueSelecionada}
//                     onChange={(e) =>
//                       handleQuantityChange(produto.id, e.target.value)
//                     }
//                     min="0"
//                     max={produto.estoque}
//                     required
//                     readOnly // Adicione o atributo readOnly aqui
//                   />
//                   {produto.estoqueSelecionada > produto.estoque && (
//                     <span className="font-normal text-red-500 text-xs">
//                       estoque máxima em estoque atingida: {produto.estoque}
//                     </span>
//                   )}
//                 </div>
//                 <button
//                   className="inline-flex items-center justify-center p-2 text-sm font-medium border rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 bg-gray-200 text-gray-600 hover:bg-gray-300"
//                   type="button"
//                   onClick={() => {
//                     if (produto.estoqueSelecionada < produto.estoque) {
//                       handleIncrease(produto.id);
//                     } else {
//                       alert(`Estoque máxima atingida: (${produto.estoque})`);
//                     }
//                   }}
//                 >
//                   <span className="sr-only">Aumentar</span>
//                   <svg
//                     className="w-2 h-2"
//                     aria-hidden="true"
//                     xmlns="http://www.w3.org/2000/svg"
//                     fill="none"
//                     viewBox="0 0 18 18"
//                   >
//                     <path
//                       stroke="currentColor"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                       strokeWidth="2"
//                       d="M9 1v16M1 9h16"
//                     />
//                   </svg>
//                 </button>
//               </div>
//             </td>
//             <td className="px-4 py-4 m-2 flex items-center justify-center font-semibold text-white">
//               R$
//               {(produto.estoqueSelecionada * produto.preco).toFixed(2)}
//             </td>
//           </tr>
//           <tr className="text-center">
//             <td colSpan="4">
//               <button className="m-2" onClick={handleAddToCart}>
//                 <span className="uppercase inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
//                   ADICIONAR NO CARRINHO
//                 </span>
//               </button>
//             </td>
//           </tr>
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default ProductCard;

import React from "react";

const ProductCard = ({
  produto,
  handleDecrease,
  handleQuantityChange,
  handleIncrease,
  adicionarProdutoAoCarrinho,
}) => {
  const handleAddToCart = () => {
    if (produto.estoqueSelecionada > 0) {
      adicionarProdutoAoCarrinho(produto);
      handleQuantityChange(produto.id, 0);
    } else {
      console.log(
        "Não é possível adicionar ao carrinho. Estoque selecionado é 0."
      );
    }
  };

  // Garantir que produto.preco é um número
  const preco = Number(produto.preco);

  return (
    <div
      className={`lg:m-4 m-2 w-full lg:w-64 rounded-lg shadow-lg bg-white border ${
        produto.estoque === 0 ? "opacity-50" : ""
      }`}
    >
      <img
        className="rounded-t-lg w-full h-48 object-cover"
        src={produto.imagem_url}
        alt={produto.nome_produto}
      />

      {/* <div className="flex test2  flex-col lg:h-80 justify-between"> */}
      <div className="flex  flex-col lg:h-80 justify-between">
        <div className=" pr-3 pl-3">
          <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
            {produto.nome_produto}
          </h5>
          <div className="flex lg:flex-col flex-wrap justify-between ">
            <div>
              <p className="mb-2 text-lg text-gray-700">R${preco.toFixed(2)}</p>
              <p className="mb-2 text-gray-600">Tamanho: {produto.tamanho}</p>
            </div>
            <div>
              <p className="mb-4 text-gray-600">{produto.descricao}</p>
              <p className="mb-4 text-gray-600">
                Estoque: {produto.estoque === 0 ? "Esgotado" : produto.estoque}
              </p>
            </div>
          </div>
        </div>

        <div className="pr-3 pl-3">
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center space-x-3">
              <button
                className="inline-flex items-center justify-center p-2 text-sm font-medium border rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 bg-gray-200 text-gray-600 hover:bg-gray-300"
                type="button"
                onClick={() => handleDecrease(produto.id)}
              >
                <span className="sr-only">Diminuir</span>
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 2"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1h16"
                  />
                </svg>
              </button>

              <input
                type="number"
                className="text-center w-14 border text-sm rounded-lg block px-2.5 py-1 bg-gray-100 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500"
                value={produto.estoqueSelecionada}
                onChange={(e) =>
                  handleQuantityChange(produto.id, e.target.value)
                }
                min="0"
                max={produto.estoque}
                readOnly
              />

              <button
                className="inline-flex items-center justify-center p-2 text-sm font-medium border rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 bg-gray-200 text-gray-600 hover:bg-gray-300"
                type="button"
                onClick={() => {
                  if (produto.estoqueSelecionada < produto.estoque) {
                    handleIncrease(produto.id);
                  } else {
                    alert(`Estoque máxima atingida: (${produto.estoque})`);
                  }
                }}
              >
                <span className="sr-only">Aumentar</span>
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </button>
            </div>

            <span className="font-semibold text-gray-900">
              R${(produto.estoqueSelecionada * preco).toFixed(2)}
            </span>
          </div>
          {produto.estoqueSelecionada > produto.estoque && (
            <span className="block text-red-500 text-sm mt-1">
              Estoque máxima atingida: {produto.estoque}
            </span>
          )}
        </div>
      </div>
      <div className="p-2">
        <button
          className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={handleAddToCart}
        >
          Adicionar ao Carrinho
        </button>
      </div>
    </div>
  );
};

export default ProductCard;
