// LoginTextInput.js
import React from "react";

function LoginTextInput({
  id,
  label,
  value,
  onChange,
  type = "text",
  required,
}) {
  return (
    <div>
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-white"
      >
        {label}
      </label>
      <input
        type={type}
        id={id}
        value={value}
        onChange={onChange}
        className="border sm:text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
        required={required}
      />
    </div>
  );
}

export default LoginTextInput;
