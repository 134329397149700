// Login.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../utils/api";
import LoginButton from "../components/componetsLogin/LoginButton";
import LoginTextInput from "../components/componetsLogin/LoginTextInput";
import { useAuth } from "../components/componetsLogin/AuthContext";

function Login() {
  const [nome, setNome] = useState("");
  const [senha, setSenha] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth(); 
  
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
        const loginResponse = await api.post("/usuarios/login", {
            nome: nome,
            senha: senha,
        });

        console.log("Resposta do login:", loginResponse.data);

        if (loginResponse.status === 200) {
            const userData = loginResponse.data.user;

            if (userData && userData.nome) {
                localStorage.setItem("userData", JSON.stringify(userData));
                await login(userData); 
                navigate("/loja", { state: { userData: loginResponse.data } });
            } else {
                setMessage("Nome ou senha incorretos. Tente novamente.");
            }
        } else {
            setMessage("Nome ou senha incorretos. Tente novamente.");
        }
    } catch (error) {
        console.error("Ocorreu um erro ao fazer login:", error);
        setMessage("Erro ao fazer login. Tente novamente mais tarde.");
    }
  };
 
  return (
    <section className="h-screen bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full  rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-gray-800 border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-white">
              Faça login
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleLogin}>
              <LoginTextInput
                id="nome"
                label="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                required
              />
              <LoginTextInput
                id="senha"
                label="Senha"
                type="password"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
                required
              />
              <div>
                <p className="text-white">
                  Caso tenha esquecido a senha, entre em contato com a
                  administração. (92)9 9114-3062
                </p>
              </div>
              <LoginButton onClick={handleLogin} text="Entrar" />
              <p>{message}</p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
