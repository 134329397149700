import React from "react";
import IndividualOrder from "./IndividualOrder";

const GroupedOrders = ({ groupedOrders }) => {
  return (
    <div className="m-2 flex text-center flex-col items-center">
      {Object.keys(groupedOrders).map((key) => {
        const orders = groupedOrders[key];
        return (
          <div className="w-full CorAZulFundo text-white" key={key}>
            <table className="w-full border-collapse border border-slate-500 ...">
              <thead>
                <tr>
                  <th className="cortes border border-slate-600 ...">Data</th>
                  <th className="cortes border border-slate-600 ...">Status</th>
                  <th className="cortes border border-slate-600 ...">Produto</th>
                  <th className="cortes border border-slate-600 ...">Qtd</th>
                  <th className="cortes border border-slate-600 ...">Preço</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <IndividualOrder key={order.id} order={order} />
                ))}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};

export default GroupedOrders;
