import React from "react";
import LogoComponent from "./LogoComponent";
import Sidebar from "../componetsSidebar/Sidebar";

const NavbarOffic = () => {
  return (
    <div>
      <nav className="bg-gray-800 border-gray-700">
        <div className=" max-w-screen-full flex flex-wrap items-center justify-between mx-auto p-2 mr-5 ml-5 ">
          <div className="items-center flex flex-wrap inline-flex rounded-md shadow-sm m-1">
            <Sidebar />
            <LogoComponent />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavbarOffic;
