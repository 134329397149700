// o erro esta aqui

import "../index.css";
import "../global.css";

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../components/componetsLogin/AuthContext"; // Importe o contexto de autenticação
import Home from "../pages/Home";
import Login from "../pages/Login";
import CreatProdutos from "../pages/CreatProdutos";
import CreatUsuarios from "../pages/CreatUsuarios";
import UpdateUsuario from "../pages/UpdateUsuario";
import Produtos from "../pages/Produtos";
import EditProduto from "../components/componets-Produtor/EditProduto";
import Loja from "../pages/Loja";
import TelaPedidos from "../pages/TelaPedidos";
import TelaHistoricoVendas from "../pages/TelaHistoricoVendas";
import TelaHistoricoPedidos from "../pages/TelaHistoricoPedidos";
import Clientes from "../pages/Clientes";
import Financeiro from "../pages/Financeiro";

const RotasProtegidas = () => {
  const { isAuthenticated, userData} = useAuth();

  
  if (!isAuthenticated) {
    // Redirecionar para a página de login se não estiver autenticado
    return <Navigate to="/"  />; 
  }

  // Verificar o tipo de usuário e redirecionar para as rotas apropriadas
  if (userData.UserType === "loja") {
    return (
      <Routes>
        <Route path="/loja" element={<Loja />} />
        <Route path="/home" element={<Home />} />
        <Route path="/Clientes" element={<Clientes />} />
        <Route path="/CreatUsuarios" element={<CreatUsuarios />} />
        <Route path="/Creat-Produtos" element={<CreatProdutos />} />
        <Route path="/UpdateUsuario/:userId" element={<UpdateUsuario />} />
        <Route path="/Editar-Produto/:userId" element={<EditProduto />} />
        <Route path="/Produtos" element={<Produtos />} />
        <Route path="/TelaPedidos" element={<TelaPedidos />} />
        <Route path="/TelaHistoricoVendas" element={<TelaHistoricoVendas />} />
        <Route
          path="/TelaHistoricoPedidos"
          element={<TelaHistoricoPedidos />}
        />
        <Route path="/Financeiro" element={<Financeiro />} />
      </Routes>
    );
  } else if (userData.UserType === "cliente" || userData.UserType === "loja") {
    return (
      <Routes>
        <Route path="/loja" element={<Loja />} />
        <Route
          path="/TelaHistoricoPedidos"
          element={<TelaHistoricoPedidos />}
        />
      </Routes>
    );
  } else {
    // Adicione lógica para outros tipos de usuários, se necessário
    return <Navigate to="/" />;
  }
};

const Rotas = () => {
  return (
    <div>
      <Routes>
        {/* Rota de login pública */}
        <Route path="/" element={<Login />} />
        {/* Rotas protegidas */}
        <Route path="/*" element={<RotasProtegidas />} />
      </Routes>
    </div>
  );
};

export default Rotas;

