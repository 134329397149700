import React from "react";

function CreatSubmitButton({ text }) {
  return (
    <button
      type="submit"
      className="mt-4 mx-auto flex m-2 text-white  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
    >
      {text}
    </button>
  );
}

export default CreatSubmitButton;
