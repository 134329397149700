import React from "react";

const PedidoItem = ({ order }) => (
  <tr key={order.id}>
    <td className="border border-slate-700">{order.nome_produto}</td>
    <td className="border border-slate-700">{order.tamanho}</td>
    <td className="border border-slate-700">{order.quantidadeSelecionada}</td>
  </tr>
);

export default PedidoItem;
