import React, { useState, useEffect } from "react";
import Loading from "./Loading";
import Error from "./Error";
import PedidoGroupConfirmado from "./PedidoGroupConfirmado";

const ListaPedidosAguardando = () => {
  const [pedidos, setPedidos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedUsers, setExpandedUsers] = useState([]);
  const novoStatus = "Confirmado";

  const handleConfirmarClick = async (pedidoIds) => {
    try {
      const promises = pedidoIds.map(async (pedidoId) => {
        const response = await fetch(
          `${process.env.REACT_APP_HOST_API}/pedidos/${pedidoId}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ status: novoStatus }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to update pedido");
        }

        return { pedidoId, status: novoStatus };
      });

      const updatedPedidos = await Promise.all(promises);

      setPedidos((pedidos) =>
        pedidos.map((pedido) =>
          updatedPedidos.find(
            (updatedPedido) => updatedPedido.pedidoId === pedido.id
          )
            ? { ...pedido, status: novoStatus }
            : pedido
        )
      );
    } catch (error) {
      console.error("Error updating pedido:", error.message);
    }
  };

  const handleDeleteClick = async (pedidoId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST_API}/pedidos/${pedidoId}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete pedido");
      }

      setPedidos((pedidos) => pedidos.filter((pedido) => pedido.id !== pedidoId));
    } catch (error) {
      console.error("Error deleting pedido:", error.message);
    }
  };

  useEffect(() => {
    const fetchPedidos = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_HOST_API}/pedidos`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch pedidos");
        }

        const pedidosData = await response.json();
        setPedidos(pedidosData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPedidos(); 
  }, []);

  const toggleUserOrders = (userId) => {
    setExpandedUsers((prevExpandedUsers) =>
      prevExpandedUsers.includes(userId)
        ? prevExpandedUsers.filter((id) => id !== userId)
        : [...prevExpandedUsers, userId]
    );
  };

  const groupOrders = () => {
    const groupedOrders = {};

    pedidos.forEach((pedido) => {
      const key = `${pedido.nome_cliente}`;

      if (!groupedOrders[key]) {
        groupedOrders[key] = [];
      }

      groupedOrders[key].push(pedido);
    });

    return groupedOrders;
  };

  const groupedOrders = groupOrders();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error} />;
  }

  return (
    <div>
      <div className="flex flex-wrap flex-row items-start">
        {Object.keys(groupedOrders).map((key) => {
          const orders = groupedOrders[key];

          if (orders[0].status === "Aguardando") {
            return (
              <PedidoGroupConfirmado
                key={key}
                orders={orders}
                expanded={expandedUsers.includes(orders[0].id)}
                toggleUserOrders={() => toggleUserOrders(orders[0].id)}
                handleConfirmarClick={() =>
                  handleConfirmarClick(orders.map((pedido) => pedido.id))
                }
                handleDeleteClick={handleDeleteClick}
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default ListaPedidosAguardando;
