import React, { useState, useEffect } from "react";

const PedidosTab = () => {
  const [pedidosList, setPedidosList] = useState([]);

  const fetchPedidosData = async () => {
    try {
      const produtosResponse = await fetch(
        `${process.env.REACT_APP_HOST_API}/pedidos`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!produtosResponse.ok) {
        throw new Error("Failed to fetch pedidos");
      }

      const pedidosData = await produtosResponse.json();
      setPedidosList(pedidosData);
    } catch (error) {
      console.error("Error fetching pedidos data:", error);
    }
  };

  useEffect(() => {
    fetchPedidosData();
  }, []);

  return (
    <div className="lg:w-2/3 w-full h-72 p-3.5 shadow-xl CorAZulFundo overflow-auto lg:rounded-2xl border-solid border-2 border-black">
      <div className="w-full h-full overflow-auto">
        <p>Pedidos</p>

        <table className="w-full md:w-screen lg:w-full border-collapse border border-slate-500">
          <thead>
            <tr>
              <th className="cortes border border-slate-600">Cliente</th>
              <th className="cortes border border-slate-600">Produtos</th>
              <th className="cortes border border-slate-600">Quantidade</th>
              <th className="cortes border border-slate-600">Valor</th>
              <th className="cortes border border-slate-600">Status</th>
            </tr>
          </thead>
          <tbody>
            {pedidosList.map((pedidos) => (
              <tr key={pedidos.id}>
                <td className="border border-slate-700">{pedidos.nome_cliente}</td>
                <td className="border border-slate-700">{pedidos.nome_produto}</td>
                <td className="border border-slate-700">{pedidos.quantidadeSelecionada}</td>
                <td className="border border-slate-700">{pedidos.preco}</td>
                <td className="border border-slate-700">{pedidos.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PedidosTab;
