import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import GroupedOrders from "./GroupedOrders";

const HistoricoPedidos = () => {
  const [userList, setUserList] = useState([]);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData ? userData.id : null;
 
  useEffect(() => {
    const fetchUserOrders = async () => {
      try {
        const usersResponse = await fetch(
          `${process.env.REACT_APP_HOST_API}/vendas/cliente/${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!usersResponse.ok) {
          throw new Error("Failed to fetch users");
        }

        const usersData = await usersResponse.json();
        setUserList(usersData);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    if (userId) {
      fetchUserOrders();
    }
  }, [userId]);

  const groupOrders = () => {
    const sortedUserList = [...userList].sort((a, b) => {
      return new Date(b.data) - new Date(a.data);
    });

    const groupedOrders = {};

    sortedUserList.forEach((item) => {
      const key = `${item.data_criada}_${item.nome_cliente}`;

      if (!groupedOrders[key]) {
        groupedOrders[key] = [];
      }

      groupedOrders[key].push(item);
    });

    return groupedOrders;
  };

  const groupedOrders = groupOrders();

  return (
    <div>
      <ol className="list-reset flex justify-center items-center">
        <li>
          <Link
            to="/loja"
            className=" transition duration-150 ease-in-out text-primary-400 hover:text-primary-500 focus:text-primary-500 active:text-primary-600"
          > 
            Home
          </Link>
        </li>
        <li>
          <span className="mx-2 text-neutral-400">/</span>
        </li>
        <li className="text-neutral-400">Pedidos</li>
      </ol>
      <GroupedOrders groupedOrders={groupedOrders} />
    </div>
  );
};

export default HistoricoPedidos;
